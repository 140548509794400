@charset "UTF-8";
/*
Theme Name: Servhidrau 2018
Version: 2018
Theme URI: https://servhidrau.com.br
Author: At: Wortek Studio - Brazil branch - by: Wanderson Rosa
Author URI: https://wortek.com.br
Description: Developed by the designer/developer Wanderson Rosa from Wortek Studio in Brazil. info@wortek.com.br
Text Domain: twentyseventeen
Tags: one-column, two-columns, right-sidebar, flexible-header, accessibility-ready, custom-colors, custom-header, custom-menu, custom-logo, editor-style, featured-images, footer-widgets, post-formats, rtl-language-support, sticky-post, theme-options, threaded-comments, translation-ready
*/
/*
PARA JESUS TODA HONRA E TODA A GLÓRIA
*/
/*External Styles*/
.company-main-blue {
  color: #034693;
}

.company-main-red {
  color: #d71921;
}

.text-black {
  color: #000;
}

.bg-page {
  background: #f2f6f9;
}

.bg-company-main-blue {
  background: #034693;
}

.bg-company-main-red {
  background: #d71921;
}

.bg-newsletter {
  background: #d9e3ef;
}

.bg-footer {
  background: #034693;
}

.no-bg {
  background-color: rgba(255, 255, 255, 0) !important;
}

.navbar {
  border-bottom: 1px solid #d9e3ef;
}

.nav-link {
  font-size: 13px;
  font-weight: 700;
  color: #034693;
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(3, 70, 147, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.cat-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.cat-item a {
  color: #7a84a1;
  display: block;
  font-size: 14px;
  line-height: 1.5rem;
  padding: 0.25rem 1rem;
  text-decoration: none !important;
}

.cat-item a:hover {
  background: #f8f9fa;
  color: #000;
}

.carousel-caption {
  top: 30%;
}

.title-border-bottom {
  border-bottom: 5px solid #d9e3ef;
}

.custom-card-img img {
  width: 80%;
  height: 80%;
}

.newsletter {
  border-top: 1px solid #d2dff0;
}

/* 
@import "_cards.scss";
@import "font-awesome.scss";
@import "fonts.scss";
@import "cleaners.scss";
@import "floats.scss";
@import "header.scss";
@import "buttons.scss";
@import "sidebar.scss";
@import "tooltip.scss";
@import "forms.scss";
@import "footer.scss";
@import "landing-pages.scss";
@import "modal.scss";
@import "_margins.scss"; */

/*# sourceMappingURL=style.css.map */
